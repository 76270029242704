import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageTemplateProps {
  data: {
    page: GatsbyTypes.WpVacature
  }
  location?: any
  prefix?: string
}

export interface VacancyContextProps {
  // eslint-disable-next-line
  preview: GatsbyTypes.WpVacature_Vacancypreview
  title: string
}

export const VacancyContext = React.createContext({})

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: {
      title,
      vacancypreview,
      databaseId,
      flexContent,
      seo,
      locale,
      translations,
    },
  },
  location = {},
  prefix = 'Vacature_Flexcontent',
}) => (
  <VacancyContext.Provider
    value={{
      preview: vacancypreview,
      title,
    }}
  >
    <Layout
      pageId={databaseId}
      locale={locale?.locale}
      translations={translations}
    >
      <SEO seo={seo} />
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
      />
    </Layout>
  </VacancyContext.Provider>
)

export const pageQuery = graphql`
  query vacancyById($pageId: String!) {
    page: wpVacature(id: { eq: $pageId }) {
      ...generalVacancyFragment
    }
  }
`

export default PageTemplate
